@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.btn{
@apply border-2 py-3 px-20 rounded-md font-body font-bold text-sm uppercase cursor-pointer tracking-wider; 
}

.input{
@apply h-[3rem]
     bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-green-600 focus:ring-1 focus:ring-green-600
      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
      invalid:border-pink-500 invalid:text-pink-600
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500
      font-body px-3 py-2 mb-5
}

.textarea{
@apply h-[10rem]
     bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
      focus:outline-none focus:border-green-600 focus:ring-1 focus:ring-green-600
      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
      invalid:border-pink-500 invalid:text-pink-600
      focus:invalid:border-pink-500 focus:invalid:ring-pink-500
      font-body px-3 py-2 mb-5
}

body{
  background-color: #f8fafc;
}

::selection {
  background: #94a3b8;
}
